<template>
  <v-sheet  :color="wsBACKGROUND" class="d-flex justify-center" >

    <v-sheet class="d-flex" width="100%"   :color="wsBACKGROUND" >
      <v-sheet v-if="displayHide('sm')" class="py-9"
               :color="wsDARKER"
               min-height="75vh"
               min-width="250"
               dark>
        <h3 class="font-weight-medium text-center px-6">Документація:</h3>

        <v-simple-table class="mt-9 topicHover" dense :style="`background : ${ wsDARK }`" >
          <tbody class="topicHover">
          <tr class="topicHover" v-for="(item,i) in topics" :key="i + '_topic'"
              style="cursor : pointer"
              :style="selectedTopic === i ? `background : ${wsDARKER}` : '' "
              @click="selectedTopic = i " >
            <td><h5 class="font-weight-medium px-2">{{ i+1 }}. {{ item.title}}</h5>   </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
      <v-sheet v-else class="py-9"
               :color="wsDARK"
               min-height="75vh"
               min-width="50" max-width="50"
               dark>
<!--        <v-btn icon class="d-block mx-auto">-->
<!--          <v-icon>mdi-chevron-right</v-icon>-->
<!--        </v-btn>-->


        <v-simple-table class="mt-9 topicHover" dense :style="`background : ${ wsDARK }`" >
          <tbody class="topicHover">
          <tr class="topicHover" v-for="(item,i) in topics" :key="i + '_topic'"
              style="cursor : pointer"
              :style="selectedTopic === i ? `background : ${wsDARKER}` : '' "
              @click="selectedTopic = i " >
            <td><h5 class="font-weight-medium px-2">{{ i+1 }}</h5>   </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
      <v-sheet width="100%"
               :class="displayHide('sm') ? 'pa-6' : 'pa-3' "
               :color="wsLIGHTCARD">
        <h2 v-if="displayHide('sm')" :style="`color : ${wsDARK}`" style="line-height: 1.2" class="mt-3 text-center">
          {{ topics[selectedTopic].title }}</h2>
        <h2 v-else :style="`color : ${wsDARK}`" style="line-height: 1.2" class="text-center mt-3">
          {{ topics[selectedTopic].title }}
        </h2>

        <div v-if="topics.length > 0" class=" mt-6" >
          <div  v-html="topics[selectedTopic].content"></div>
        </div>
      </v-sheet>
    </v-sheet>

  </v-sheet>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "HomeDocumentation",
  data() {
    return {
      topics : [],
      selectedTopic : 0
    }
  },
  methods : {
    ...mapActions('documentation', [
      'GET_TOPICS_PUBLIC'
    ]),
  },
  mounted() {
    this.GET_TOPICS_PUBLIC(this.$store.state.project).then((out)=> {
      if ( out.result ) {
        this.topics = out.data.topics
      }
    })
  }

}
</script>

<style scoped>
.topicHover tr:hover  {
  background: #4E7D95 !important;
}
</style>